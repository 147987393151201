import { asyncAction } from '../common';
import {
  GET_SVCPNS,
  GET_UNKNOWN_SVCPNS,
  ADD_SVSPN,
  TOGGLE_ADD_SVCPN,
  EDIT_SVCPN,
  CLEAR_EDIT_SVCPN_ERROR,
  EXPORT_SVCPN_CSV,
} from './actionTypes';

export const getSvcpns = asyncAction(GET_SVCPNS);
export const getUnknownSvcpns = asyncAction(GET_UNKNOWN_SVCPNS);
export const addSvspn = asyncAction(ADD_SVSPN);
export const toggleAddSvcpnModal = asyncAction(TOGGLE_ADD_SVCPN);
export const editSvcpn = asyncAction(EDIT_SVCPN);
export const clearEditSvcpnError = asyncAction(CLEAR_EDIT_SVCPN_ERROR);
export const exportCsv = asyncAction(EXPORT_SVCPN_CSV);
