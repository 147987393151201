import { makeReducer } from '../common';
import { GET_PROJECTS, TOGGLE_ADD_PROJECT } from './actionTypes';

export default makeReducer(
  {
    [GET_PROJECTS.RUN]: (state) => ({
      ...state,
      isLoading: true,
      error: null,
    }),
    [GET_PROJECTS.SUCCESS]: (state, { payload }) => ({
      ...state,
      projects: payload,
      isLoading: false,
    }),
    [GET_PROJECTS.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      isLoading: false,
      error,
    }),
    [TOGGLE_ADD_PROJECT.RUN]: (state) => ({
      ...state,
      isOpen: !state.isOpen,
      error: null,
    }),
    [TOGGLE_ADD_PROJECT.SUCCESS]: (state) => ({
      ...state,
      isOpen: false,
      isLoading: false,
    }),
    [TOGGLE_ADD_PROJECT.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      isOpen: false,
      error,
    }),
  },
  {
    projects: [],
    error: null,
    isLoading: false,
    isOpen: false,
  },
);
