import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const StyledFormGroup = styled(FormGroup)`
  border: 1px solid rgba(194, 195, 196, 1);
  padding: 10px 35px;
  border-radius: 4px;
  font-size: 14px;

  p {
    font-size: 12px;
    color: rgba(111, 114, 118, 1);
    font-weight: 400;
    margin-bottom: 0px;
  }
`;

const TwoFactorAuthMethods = () => {
  const [selectedMethod, setSelectedMethod] = useState('authenticatorApp');
  const history = useHistory();
  const { currentUser } = useSelector((state) => ({
    currentUser: state.users.currentUser,
  }));

  const handleMethodChange = (e) => {
    setSelectedMethod(e.target.value);
  };

  useEffect(() => {
    if (!currentUser) {
      history.push('/login');
    } else if (currentUser.role === 'CLIENT') {
      history.push('/home');
    }
  }, [currentUser, history]);

  const handleContinue = () => {
    if (selectedMethod === 'authenticatorApp') {
      history.push('/2fa-login/authenticator');
    } else if (selectedMethod === 'smsCode') {
      history.push('/2fa-login/sms');
    }
  };

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card
                className="overflow-hidden p-4"
                style={{
                  minHeight: '500px',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <CardBody className="d-flex flex-column justify-content-between">
                  <div>
                    <h4 className="text-left mb-4">
                      Two-Factor Authentication
                    </h4>
                    <p className="text-muted text-left mb-4">
                      ClimeCo requires you to protect your account with 2FA. How
                      would you like to receive your one-time authentication
                      code for this session?
                    </p>
                    <Form>
                      <StyledFormGroup
                        check
                        className={cx('mb-3', {
                          'selected-method':
                            selectedMethod === 'authenticatorApp',
                        })}
                      >
                        <Label check>
                          <Input
                            type="radio"
                            name="2faMethod"
                            value="authenticatorApp"
                            checked={selectedMethod === 'authenticatorApp'}
                            onChange={handleMethodChange}
                          />
                          Authenticator App
                          <p className="text-muted small">
                            Use an authentication app to generate a one-time
                            verification code
                          </p>
                        </Label>
                      </StyledFormGroup>

                      {currentUser?.phoneNumber && (
                        <StyledFormGroup
                          check
                          className={cx('mb-3', {
                            'selected-method': selectedMethod === 'smsCode',
                          })}
                        >
                          <Label check>
                            <Input
                              type="radio"
                              name="2faMethod"
                              value="smsCode"
                              checked={selectedMethod === 'smsCode'}
                              onChange={handleMethodChange}
                            />
                            SMS-code
                            <p className="text-muted small">
                              Receive a one-time code via your phone number
                            </p>
                          </Label>
                        </StyledFormGroup>
                      )}
                    </Form>
                  </div>
                  <div>
                    <div className="d-grid">
                      <Button color="primary" onClick={handleContinue}>
                        Continue
                      </Button>
                    </div>
                    <div className="mt-2 text-center">
                      <Button
                        color="link"
                        onClick={() => history.push('/login')}
                      >
                        &lt; Back
                      </Button>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TwoFactorAuthMethods;
