import { takeEvery, call, put } from 'redux-saga/effects';
import { getProjects } from './actions';
import { GET_PROJECTS } from './actionTypes';
import { projects } from '../../api';

function* getProjectsSaga() {
  try {
    const response = yield call(projects.getProjects);
    yield put(getProjects.success(response.data.data));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export default function* projectSaga() {
  yield takeEvery(GET_PROJECTS.RUN, getProjectsSaga);
}
