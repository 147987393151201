import React, { useState } from 'react';

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from 'reactstrap';

import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import * as Yup from 'yup';
import { useFormik } from 'formik';

import PageTitle from '../../components/Common/PageTitle';
import { loginUser } from '../../store/users';

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const togglePasswordShown = () => setIsPasswordShown(!isPasswordShown);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: '',
      password: '',
      rememberMe: false,
    },
    validationSchema: Yup.object({
      email: Yup.string().required('Please Enter Your Email'),
      password: Yup.string().required('Please Enter Your Password'),
    }),
    onSubmit: (params) => {
      dispatch(loginUser({ params, history }));
    },
  });

  const { error, message } = useSelector((state) => ({
    error: state.users.error,
    message: state.users.message,
  }));

  return (
    <React.Fragment>
      <PageTitle title="Login" />
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card
                className="overflow-hidden p-4"
                style={{
                  minHeight: '500px',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div className="bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h4>Log in</h4>
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0 d-flex flex-column justify-content-between">
                  <Form
                    className="form-horizontal d-flex flex-column justify-content-between"
                    style={{ flex: 1 }}
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <div>
                      {error ? <Alert color="danger">{error}</Alert> : null}
                      {message ? (
                        <Alert color="success">{message}</Alert>
                      ) : null}

                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ''}
                          invalid={
                            !!(
                              validation.touched.email &&
                              validation.errors.email
                            )
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <div className="input-group">
                          <Input
                            name="password"
                            value={validation.values.password || ''}
                            type={isPasswordShown ? 'text' : 'password'}
                            placeholder="Enter Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              !!(
                                validation.touched.password &&
                                validation.errors.password
                              )
                            }
                          />
                          <button
                            onClick={togglePasswordShown}
                            className="btn btn-light"
                            type="button"
                          >
                            <i
                              className={
                                !isPasswordShown
                                  ? 'mdi mdi-eye-outline'
                                  : 'mdi mdi-eye-off-outline'
                              }
                            ></i>
                          </button>
                          {validation.touched.password &&
                          validation.errors.password ? (
                            <FormFeedback type="invalid">
                              {validation.errors.password}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </div>

                      <div className="form-check">
                        <input
                          onChange={validation.handleChange}
                          name="rememberMe"
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div>
                    </div>

                    <div>
                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          Log In
                        </button>
                      </div>
                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password?
                        </Link>
                      </div>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Login;
