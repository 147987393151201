import React from 'react';
import {
  Button,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from 'reactstrap';
import PropTypes from 'prop-types';
import ThreeDotsMenu from '../../components/Common/ThreeDotsMenu';
import ChangePassword from './ChangePassword';
import DeleteUser from './DeleteUser';
import EditUser from './EditUser';
import { useToggle } from '../../hooks';
import { withUser } from '../../context/UserContext';

const UserMenu = ({ row, user }) => {
  const [isDropdownOpen, toggleDropdown] = useToggle();
  const [isEditOpen, toggleEditModal] = useToggle();
  const [isChangePasswordOpen, toggleChangePasswordModal] = useToggle();
  const [isDeleteOpen, toggleDeleteModal] = useToggle();

  const disabled = user && user.role !== 'ADMIN';

  return (
    <div>
      <Dropdown isOpen={isDropdownOpen} toggle={toggleDropdown}>
        <DropdownToggle
          disabled={disabled}
          color="link"
          className="shadow-none"
        >
          <ThreeDotsMenu />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem>
            <Button color="link" onClick={toggleChangePasswordModal}>
              Change Password
            </Button>
          </DropdownItem>
          <DropdownItem>
            <Button color="link" onClick={toggleEditModal}>
              <span>Edit User</span>
            </Button>
          </DropdownItem>
          <DropdownItem>
            <Button color="link" onClick={toggleDeleteModal}>
              <span>Delete</span>
            </Button>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <ChangePassword
        isOpen={isChangePasswordOpen}
        toggleModal={toggleChangePasswordModal}
        onClick={toggleDropdown}
        user={row}
      />
      <EditUser
        isOpen={isEditOpen}
        toggleModal={toggleEditModal}
        onClick={toggleDropdown}
        user={row}
      />
      <DeleteUser
        isOpen={isDeleteOpen}
        toggleModal={toggleDeleteModal}
        onClick={toggleDropdown}
        user={row}
        currentUser={user}
      />
    </div>
  );
};

UserMenu.propTypes = {
  row: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default withUser(UserMenu);
