import { takeEvery, call, put } from 'redux-saga/effects';
import { saveAs } from 'file-saver';
import { asyncSaga } from '../common';

import {
  getRecords,
  fetchFilters,
  assignToProject,
  toggleAssignAllModal,
  assignAllToProject,
  editRecord,
  toggleAssignRecordsModal,
  exportCsv,
  bulkAction,
  processRecord,
  moveRecordsToBilled,
  setRowDataArray,
  toggleBilledRecordsModal,
} from './actions';
import {
  GET_RECORDS,
  FETCH_FILTERS,
  ASSIGN_RECORDS_TO_PROJECT,
  ASSIGN_ALL_TO_PROJECT,
  EDIT_RECORD,
  EXPORT_CSV,
  PROCESS_RECORD,
  MOVE_RECORDS_TO_BILLED,
  BULK_ACTION,
} from './actionTypes';
import { records } from '../../api';
import Notification from '../../components/Common/Notification';

function* moveRecordsToBilledSaga({ payload: { params } }) {
  try {
    const response = yield call(records.moveRecordsToBilled, params);
    yield put(moveRecordsToBilled.success(response));
    yield put(toggleBilledRecordsModal.success());
    yield put(setRowDataArray.success([]));
  } catch (error) {
    switch (error.response?.status) {
      case 422:
        return yield put(moveRecordsToBilled.fail(error.response.data.message));
      case 409:
        return yield put(
          moveRecordsToBilled.fail('This name already exists, try another one'),
        );
      default:
        return yield put(moveRecordsToBilled.fail('Something went wrong'));
    }
  }
}

function* assignToProjectSaga({
  payload: { id, params, queryParams, projectName },
}) {
  try {
    const response = yield call(records.assignToProject, {
      id,
      params,
      queryParams,
    });
    yield put(assignToProject.success(response));
    yield put(toggleAssignRecordsModal.success());
    Notification(
      'success',
      `${response.data.data.numberOfAffectedRows} records have been assigned to the '${projectName}' project`,
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    Notification('error', error.message);

    switch (error.response?.status) {
      case 422:
        return yield put(assignToProject.fail(error.message));
      default:
        return yield put(assignToProject.fail('Something went wrong'));
    }
  }
}

function* processRecordSaga({ payload: { params, queryParams } }) {
  try {
    const response = yield call(records.processRecord, {
      params,
      queryParams,
    });
    yield put(processRecord.success(response));
    Notification(
      'success',
      `${params.recordIds.length} records are being processed.`,
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    switch (error.response?.status) {
      case 422:
        return yield put(processRecord.fail(error.message));
      default:
        return yield put(processRecord.fail('Something went wrong'));
    }
  }
}

function* assignAllToProjectSaga({ payload: { params, queryParams } }) {
  try {
    const response = yield call(records.assignAllToProject, {
      params,
      queryParams,
    });
    yield put(assignAllToProject.success(response));
    yield put(toggleAssignAllModal.success());
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    switch (error.response?.status) {
      case 422:
        return yield put(assignAllToProject.fail(error.message));
      default:
        return yield put(assignAllToProject.fail('Something went wrong'));
    }
  }
}

function* editProjectSaga({ payload: { id, params } }) {
  try {
    const response = yield call(records.editRecord, { id, params });
    const { indulgence } = response.data.data;
    yield put(editRecord.success({ indulgence }));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    switch (error.response?.status) {
      case 422:
        return yield put(editRecord.fail(error.message));
      case 409:
        return yield put(
          editRecord.fail('Trying to assign carbon over the limit'),
        );
      default:
        return yield put(editRecord.fail('Something went wrong'));
    }
  }
}

function* exportCsvSaga({ payload }) {
  try {
    const response = yield call(records.exportCsv, payload);
    const fileName = response.headers['content-disposition']
      .split('filename=')[1]
      .replace(/"/g, '');
    const csvBlob = new Blob([response.data], {
      type: 'text/csv',
    });
    saveAs(csvBlob, fileName);
    yield put(exportCsv.success(response));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    switch (error.response?.status) {
      case 422:
        return yield put(exportCsv.fail(error.message));
      default:
        return yield put(exportCsv.fail('Something went wrong'));
    }
  }
}

function* bulkActionSaga({ payload }) {
  try {
    const response = yield call(records.bulkAction, payload);
    yield put(bulkAction.success(response));

    switch (payload.action) {
      case 'assign':
        Notification(
          'success',
          `${response.data.data.numberOfAffectedRows} records have been assigned to the '${payload.params.projectName}' project`,
        );
        break;
      case 'bill':
        Notification(
          'success',
          `Billing summary on the selected records has been created.`,
        );
        break;
      case 'process':
        Notification(
          'success',
          `${payload.numberOfRecords} records are being processed.`,
        );
        break;
      default:
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    if (error.response?.status === 504) {
      Notification(
        'warning',
        'Your request has been timed out, please try refreshing the page within the next few minutes',
      );
    } else if (error.response?.status === 409) {
      Notification(
        'error',
        'Unable to create billing summary. A summary with the same name already exists.',
      );
    } else {
      Notification('error', error.message);
    }
    switch (error.response?.status) {
      case 422:
        return yield put(bulkAction.fail(error.message));
      default:
        return yield put(bulkAction.fail('Something went wrong'));
    }
  }
}

export default function* sage() {
  yield takeEvery(
    GET_RECORDS.RUN,
    asyncSaga((action) => records.getRecords(action), getRecords),
  );
  yield takeEvery(
    FETCH_FILTERS.RUN,
    asyncSaga((action) => records.getFilters(action), fetchFilters),
  );
  yield takeEvery(EXPORT_CSV.RUN, exportCsvSaga);
  yield takeEvery(ASSIGN_RECORDS_TO_PROJECT.RUN, assignToProjectSaga);
  yield takeEvery(ASSIGN_ALL_TO_PROJECT.RUN, assignAllToProjectSaga);
  yield takeEvery(EDIT_RECORD.RUN, editProjectSaga);
  yield takeEvery(PROCESS_RECORD.RUN, processRecordSaga);
  yield takeEvery(MOVE_RECORDS_TO_BILLED.RUN, moveRecordsToBilledSaga);
  yield takeEvery(BULK_ACTION.RUN, bulkActionSaga);
}
