import { makeReducer } from '../common';
import {
  CREATE_USER,
  DELETE_USER,
  GET_USERS,
  LOGIN_USER,
  LOGOUT_USER,
  UPDATE_USER_DATA,
  UPDATE_USER_PASSWORD,
  TOGGLE_USER_MODAL,
  SEND_PASSWORD_RESET_EMAIL,
  CHECK_TOKEN,
  UPDATE_PASSWORD,
  SEND_SMS_CODE,
  VERIFY_SMS_CODE,
  REGISTER_OTP_APP,
  VERIFY_OTP_APP,
  VERIFY_OTP_CODE,
  STORE_REMEMBER_ME,
} from './actionTypes';

export default makeReducer(
  {
    [LOGIN_USER.RUN]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [LOGIN_USER.SUCCESS]: (state, { payload: { user } }) => ({
      ...state,
      currentUser: user,
      isLoading: false,
      error: null,
    }),
    [LOGIN_USER.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      isLoading: false,
      error,
    }),
    [STORE_REMEMBER_ME.SUCCESS]: (state, { payload: { rememberMe } }) => ({
      ...state,
      rememberMe,
    }),
    [SEND_SMS_CODE.RUN]: (state) => ({
      ...state,
    }),
    [SEND_SMS_CODE.SUCCESS]: (state) => ({
      ...state,
    }),
    [SEND_SMS_CODE.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      error,
    }),
    [VERIFY_SMS_CODE.RUN]: (state) => ({
      ...state,
    }),
    [VERIFY_SMS_CODE.SUCCESS]: (state) => ({
      ...state,
    }),
    [VERIFY_SMS_CODE.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      error,
    }),
    [REGISTER_OTP_APP.RUN]: (state) => ({
      ...state,
    }),
    [REGISTER_OTP_APP.SUCCESS]: (state) => ({
      ...state,
    }),
    [REGISTER_OTP_APP.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      error,
    }),
    [VERIFY_OTP_APP.RUN]: (state) => ({
      ...state,
    }),
    [VERIFY_OTP_APP.SUCCESS]: (state) => ({
      ...state,
    }),
    [VERIFY_OTP_APP.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      error,
    }),
    [VERIFY_OTP_CODE.RUN]: (state) => ({
      ...state,
    }),
    [VERIFY_OTP_CODE.SUCCESS]: (state) => ({
      ...state,
    }),
    [VERIFY_OTP_CODE.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      error,
    }),
    [LOGOUT_USER.RUN]: (state) => ({
      ...state,
    }),
    [LOGOUT_USER.SUCCESS]: (state) => ({
      ...state,
    }),
    [GET_USERS.RUN]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [GET_USERS.SUCCESS]: (state, { payload }) => ({
      ...state,
      users: payload,
      isLoading: false,
    }),
    [GET_USERS.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      isLoading: false,
      error,
    }),
    [TOGGLE_USER_MODAL.RUN]: (state, { payload: { type = '' } }) => ({
      ...state,
      isOpen: !state.isOpen,
      modalType: type,
    }),
    [TOGGLE_USER_MODAL.SUCCESS]: (state) => ({
      ...state,
      error: null,
    }),
    [TOGGLE_USER_MODAL.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      isOpen: false,
      error,
    }),
    [CREATE_USER.RUN]: (state) => ({
      ...state,
    }),
    [CREATE_USER.SUCCESS]: (state, { payload: { user } }) => ({
      ...state,
      users: {
        results: [user, ...state.users.results],
      },
      error: null,
    }),
    [CREATE_USER.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      error,
    }),
    [UPDATE_USER_DATA.RUN]: (state) => ({
      ...state,
    }),
    [UPDATE_USER_DATA.SUCCESS]: (state, { payload: { user } }) => ({
      ...state,
      users: {
        results: state.users.results.map((row) =>
          row.id === user.id
            ? { ...user, ...{ email: user.email, role: user.role } }
            : row,
        ),
      },
      error: null,
    }),
    [UPDATE_USER_DATA.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      error,
    }),
    [UPDATE_USER_PASSWORD.RUN]: (state) => ({
      ...state,
    }),
    [UPDATE_USER_PASSWORD.SUCCESS]: (state) => ({
      ...state,
    }),
    [UPDATE_USER_PASSWORD.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      error,
    }),
    [DELETE_USER.RUN]: (state) => ({
      ...state,
    }),
    [DELETE_USER.SUCCESS]: (state, { payload: { id } }) => ({
      ...state,
      users: {
        results: state.users.results.filter((row) => row.id !== id),
      },
    }),
    [DELETE_USER.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      error,
    }),
    [SEND_PASSWORD_RESET_EMAIL.RUN]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [SEND_PASSWORD_RESET_EMAIL.SUCCESS]: (state) => ({
      ...state,
      isLoading: false,
      error: null,
    }),
    [SEND_PASSWORD_RESET_EMAIL.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      isLoading: false,
      error,
    }),
    [CHECK_TOKEN.RUN]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [CHECK_TOKEN.SUCCESS]: (state) => ({
      ...state,
      isLoading: false,
      error: null,
    }),
    [CHECK_TOKEN.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      isLoading: false,
      error,
    }),
    [UPDATE_PASSWORD.RUN]: (state) => ({
      ...state,
    }),
    [UPDATE_PASSWORD.SUCCESS]: (state) => ({
      ...state,
      message: 'Password successfully updated',
    }),
    [UPDATE_PASSWORD.FAIL]: (state, { payload: { error } }) => ({
      ...state,
      error,
    }),
  },
  {
    users: [],
    currentUser: null,
    error: null,
    isLoading: false,
    isOpen: false,
    modalType: '',
    message: '',
  },
);
