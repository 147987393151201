import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button, Col, ModalHeader, ModalBody, Row } from 'reactstrap';
import CloseIcon from '../../components/Common/CloseIcon';
import AssignToProjectForm from './AssignToProjectForm';
import { toggleAssignRecordsModal } from '../../store/records';
import { useProjects } from '../../hooks';

const Header = styled(ModalHeader)`
  padding: 16px 16px 0 16px;
  border-bottom: 1px #d3d3d3 solid;
`;

const TotalsHeader = styled(ModalHeader)`
  padding: 16px 16px 0 16px;
  border-bottom: 1px #d3d3d3 solid;

  .modal-title {
    width: 100%;
    padding: 0 28px;
  }
`;

const TitleCol = styled(Col)`
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 400;
`;

const TotalRow = styled(Row)`
  margin-bottom: 15px;
  font-size: 13px;
  font-weight: 400;
`;

const CloseButton = styled(Button)`
  width: auto;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 14px 16px;
  border: none;
`;

const AssignToProjectWrapper = ({ selectedRecords, params, rowData }) => {
  const { data: projects } = useProjects();
  const dispatch = useDispatch();

  const toggleModal = async () => {
    return dispatch(toggleAssignRecordsModal());
  };

  return (
    <>
      <Header>
        <Row>
          <TitleCol>Assign to project</TitleCol>
          <CloseButton color="link" onClick={toggleModal}>
            <CloseIcon />
          </CloseButton>
        </Row>
      </Header>
      <TotalsHeader>
        <TotalRow>
          <Col xs={8}>Total affected records</Col>
          <Col xs={4}>{rowData.length}</Col>
        </TotalRow>
        <TotalRow>
          <Col xs={8}>Total remaining carbon</Col>
          <Col xs={4}>
            {rowData.reduce((sum, o) => sum + +o.offset, 0)} tonne
          </Col>
        </TotalRow>
      </TotalsHeader>
      <ModalBody>
        <AssignToProjectForm
          data={projects}
          params={params}
          selectedRecords={selectedRecords}
        />
      </ModalBody>
    </>
  );
};

export default AssignToProjectWrapper;

AssignToProjectWrapper.propTypes = {
  params: PropTypes.object.isRequired,
  selectedRecords: PropTypes.array.isRequired,
  rowData: PropTypes.array.isRequired,
};
