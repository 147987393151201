import { asyncActionType } from '../common';

export const LOGIN_USER = asyncActionType('LOGIN_USER');
export const SEND_SMS_CODE = asyncActionType('SEND_SMS_CODE');
export const VERIFY_SMS_CODE = asyncActionType('VERIFY_SMS_CODE');
export const REGISTER_OTP_APP = asyncActionType('REGISTER_OTP_APP');
export const VERIFY_OTP_APP = asyncActionType('VERIFY_OTP_APP');
export const VERIFY_OTP_CODE = asyncActionType('VERIFY_OTP_CODE');
export const LOGOUT_USER = asyncActionType('LOGOUT_USER');
export const GET_USERS = asyncActionType('GET_USERS');
export const GET_CURRENT_USER = asyncActionType('GET_CURRENT_USER');
export const CREATE_USER = asyncActionType('CREATE_USER');
export const DELETE_USER = asyncActionType('DELETE_USER');
export const UPDATE_USER_DATA = asyncActionType('UPDATE_USER_DATA');
export const UPDATE_USER_PASSWORD = asyncActionType('UPDATE_USER_PASSWORD');
export const UPDATE_PASSWORD = asyncActionType('UPDATE_PASSWORD');
export const TOGGLE_USER_MODAL = asyncActionType('TOGGLE_USER_MODAL');
export const CHECK_TOKEN = asyncActionType('CHECK_TOKEN');
export const SEND_PASSWORD_RESET_EMAIL = asyncActionType(
  'SEND_PASSWORD_RESET_EMAIL',
);
export const STORE_REMEMBER_ME = asyncActionType('STORE_REMEMBER_ME');
